<template lang="pug">
  section.l-section
    .l-section-h
      b-card.card-transparent
        .pb-5.text-center
          .wc-i.wc-symbol.rounded(style='width: 80px; height: 80px')
          .h4.m-0.mt-2.text-uppercase.text-white
            | {{ $wc.conf.base.name }}

        .text-center.text-white.mb-4
          h3
            i.fal.fa-fw.fa-shield-alt
            |  {{ $t('session.unlock') }}

        .text-center.mb-4
          h5(v-html="$t('session.help.validates_html')")

        .d-flex.justify-content-center
          wc-loaders-cube
</template>

<script>
import { Form } from '@common/form'
import WcLoadersCube from '@components/shared/loaders/WcLoadersCube'
import apiUnlock from '@services/api/account/unlock'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'account-unlock-show',
  mixins: [Form],
  components: {
    WcLoadersCube,
  },
  computed: {
    apiParams() {
      return {
        get: [{}, this.$getDeep(this.form, 'data.attributes')],
      }
    },
  },
  methods: {
    checkToken() {
      if (!this.$route.query.unlock_token) {
        this.$router.replace(this.$route.query.redirect || { name: 'login' })
      }
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            unlock_token: {
              required,
            },
          },
        },
      },
    }
  },
  data() {
    return {
      skipFormFill: true,
      apiBase: apiUnlock,
      apiModel: 'account',
      formBootstrapDefaults: {
        get: {
          data: {
            attributes: {
              unlock_token: this.$route.query.unlock_token,
            },
          },
        },
      },
    }
  },
  created() {
    this.checkToken()
  },
  mounted() {
    this.formSubmit()
  },
}
</script>
